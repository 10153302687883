import React from "react"
import Divider from "../components/common/Divider"
import Layout from "../components/common/Layout"
import Container from "../components/common/Container"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion"
import ChevronDown from "../assets/images/icon-chevron-down.inline.svg"
import { css } from "styled-components"
import SEO from "../components/common/SEO"
import { DEVICES, FAQS } from "../config"

function Item({ children, summary }) {
  return (
    <AccordionItem
      css={`
        border-radius: 12px;
        box-shadow: 5px 5px 10px 0 #0a0a0a, -5px -5px 10px 0 #242424;
        font-size: 20px;
        line-height: 1.4;
      `}
    >
      <AccordionItemState>
        {({ expanded }) => (
          <>
            <AccordionItemHeading>
              <AccordionItemButton
                css={`
                  padding: 20px 32px;
                  cursor: pointer;
                  outline: none;
                  display: flex;
                  opacity: ${expanded ? 1 : 0.7};
                  transition: opacity 250ms ease;
                  align-items: center;
                  font-size: 20px;
                  font-weight: bold;
                  line-height: 1.4;

                  @media (max-width: ${DEVICES.mobile}px) {
                    font-size: 18px;
                    line-height: 1.33;
                  }
                `}
              >
                {summary}
                <div
                  css={`
                    display: flex;
                    margin-left: auto;
                    height: 16px;
                    width: 16px;
                  `}
                >
                  <ChevronDown
                    css={`
                      fill: #ebeaea;
                      transition: transform 250ms ease;
                      ${expanded
                        ? css`
                            transform: rotate(180deg);
                          `
                        : ""}
                    `}
                  />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel
              css={`
                padding: 0 32px;
              `}
            >
              <Divider
                css={`
                  margin: 0;
                `}
              />
              {children}
            </AccordionItemPanel>
          </>
        )}
      </AccordionItemState>
    </AccordionItem>
  )
}

function FAQ() {
  return (
    <Layout headerTheme="dark">
      <SEO title="FAQ" />
      <Divider
        css={`
          margin-bottom: 80px;
        `}
      />
      <main
        css={`
          padding-bottom: 10rem;
        `}
      >
        <Container compact>
          <h1
            css={`
              margin-bottom: 56px;
            `}
          >
            Frequently Asked Questions
          </h1>
          <Accordion
            css={`
              display: grid;
              gap: 16px;

              p,
              ol {
                opacity: 0.55;
                font-size: 14px;
                font-weight: 500;
                margin: 22px 0;
              }

              a {
                text-decoration: none;
                color: #0095ff;
                transition: all 250ms ease;
              }
            `}
            allowZeroExpanded
          >
            {FAQS.map(faq => (
              <Item key={faq.q} summary={faq.q}>
                {faq.a}
              </Item>
            ))}
          </Accordion>
        </Container>
      </main>
    </Layout>
  )
}

export default FAQ
